import React, { Component } from 'react'
import enerji from '../images/enerji.png'
export class Enerjisoyuducu extends Component {
    render() {
        return (
            <section className='bg-[#0038FF] w-full  mt-[52px]'>

                <div className='container  flex justify-between max-[770px]:flex-col  max-[620px]:items-center'>

                    <div className='p-[80px_0px] max-[850px]:p-[50px_0px] max-[770px]:p-[10px_0px] max-[850px]:flex max-[850px]:items-center max-[770px]:justify-center'>
                        <h3 className='font-[700] text-[70px]  text-[#fff] max-w-[370px] max-[980px]:text-[60px] max-[850px]:text-[50px] max-[620px]:text-[40px] max-[400px]:text-[35px] max-[370px]:text-[30px] max-[620px]:max-w-[600px] max-[320px]:text-center max-[330px]:text-[24px]'>
                            Böyük Endirim
                        </h3>
                    </div>

                    <div className='h-full max-[770px]:flex max-[770px]:justify-center'>
                        <img src={enerji} className='w-[400px] h-full max-[770px]:w-[300px] ' />
                    </div>

                    <div className='flex flex-col p-[80px_0px] gap-[10px] max-[620px]:items-center max-[770px]:items-center max-[770px]:p-[10px_0px] '>
                        <p className='font-[400] text-[16px] leading-[20px] text-[#fff] max-[320px]:text-center'>Enerji Soyuducu</p>
                        <h3 className='font-[500] text-[45px] text-[#fff] max-[980px]:text-[38px] max-[850px]:text-[30px] max-[370px]:text-[25px] max-[320px]:text-center'>Enerji Soyuducu</h3>
                        <span className='font-[400] text-[16px] leading-[20px] text-[#fff] text-start max-w-[377px] max-[980px]:text-[15px] max-[850px]:text-[13px] max-[770px]:text-center'>The camera sensors and lenses on the main and ultra-wide cameras have been upgraded.</span>

                        {/* <div className='mt-[20px]'>
                <button className='font-[500] text-[16px] leading-[20px] text-[#000] bg-[#fff] w-[130px] p-[8px] rounded-[75px] max-[850px]:text-[13px] max-[850px]:w-[85px]'>
                  Daxil Ol
                </button>
              </div> */}
                    </div>

                </div>

            </section>
        )
    }
}

export default Enerjisoyuducu