import React, { Component } from 'react'
import CardSlider from '../components/CardSlider'
import Card1 from '../components/cards/Card1'
import Card2 from '../components/cards/Card2'
import car from '../images/car.png'
import earphone from '../images/earphone.png'
import succes from '../images/succes.png'
import SectionHeader from '../components/SectionHeader'
import Offers from '../components/Offers'
import GoodSold from '../components/GoodSold'
import ViewAllBtn from '../components/ViewAllBtn'
import { connect } from 'react-redux'
import Card9 from '../components/cards/Card9'
import { getourwork } from '../actions/MainAction'

import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom'
import PhoneSvg from '../components/svg/PhoneSvg'
import SectionDescription from '../components/SectionDescription'
import MainAboutUs from '../components/MainAboutUs'
import MainSliderCard from '../components/cards/MainSliderCard'
import Enerjisoyuducu from '../components/Enerjisoyuducu'


export class MainPage extends Component {


  componentDidMount() {
    this.props.getourwork(this.props.lang_id);
  }


  render() {
    const { contentData, ourWorkData } = this.props
    // console.log(ourWorkData);

    return (
      <main>
        <Helmet>
          <title>AİK Soyuducuları | Ən Yaxşı Qiymətlərlə Soyuducu Satışı - aik.az</title>
          <meta name="description" content="AİK şirkəti ilə aik.az saytında ən son texnologiya ilə təchiz edilmiş keyfiyyətli soyuducuları ən yaxşı qiymətlərlə alın. Geniş seçim və sərfəli qiymətlər." />
          <meta name="keywords" content="“Əliyev və K” Şəxsi istehsalat Kommersiya Şirkəti, Alman texnologiyası, Vitirin soyuducuları, Mətbəx avadanlıqları, Market avadanlıqları, Soyuducu,nerjaveyka xaladenik,  Dondurucu, Su Soyuducusu, AİK, aik.az, soyuducu, soyuducu satışı, soyuducu qiymətləri, keyfiyyətli soyuducular, soyuducu al, soyuducu onlayn satış" />
          <meta property="og:title" content="AİK Soyuducuları | Ən Yaxşı Qiymətlərlə Soyuducu Satışı - aik.az" />
          <meta property="og:description" content="AİK şirkəti ilə aik.az saytında ən son texnologiya ilə təchiz edilmiş keyfiyyətli soyuducuları ən yaxşı qiymətlərlə alın. Geniş seçim və sərfəli qiymətlər." />
          <meta property="og:image" content="https://aik.az/images/logo.png" />
          <meta property="og:url" content="https://www.aik.az" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="AİK Soyuducuları | Ən Yaxşı Qiymətlərlə Soyuducu Satışı - aik.az" />
          <meta name="twitter:description" content="“Əliyev və K” Şəxsi istehsalat Kommersiya Şirkəti. AİK şirkəti ilə aik.az saytında ən son texnologiya ilə təchiz edilmiş keyfiyyətli soyuducuları ən yaxşı qiymətlərlə alın. Geniş seçim və sərfəli qiymətlər." />
          <meta name="twitter:image" content="https://aik.az/static/media/turn_on.5fc41a9c0ab733ba6cbf.png" />
        </Helmet>

        <section className='container max-[1024px]:hidden'>
          <MainSliderCard />
        </section>



        <section className='container'>
          {/* <div className='grid grid-cols-3 gap-[32px] mt-[32px] max-[950px]:grid-cols-2 max-[550px]:grid-cols-1'>
            <Card1 />
            <Card1 />
            <Card1 />
          </div> */}

          <section className='mt-[52px] flex flex-wrap gap-[20px] justify-center'>
            <Card2
              image={car}
              title='Çatdırılma'
              text='The simplified business model provides potentially lower costs.'
            />
            <Card2
              image={earphone}
              title='Texniki Dəstək'
              text='Online customer support allows companies to reach a wider customer base.'
            />
            <Card2
              image={succes}
              title='Rəsmi Zəmanət'
              text='Online customer support allows companies to reach a wider customer base.'
            />
          </section>
        </section>

        <Enerjisoyuducu />

        <section className='mt-[52px]'>
          <SectionHeader title='Super Təkliflər' />
          <SectionDescription text='"Super Təkliflər" - Endirimlər və İnanılmaz Fürsətlər!' />
          <div className='mt-[32px] flex flex-col gap-[52px]'>
            <CardSlider />

            <Offers />
          </div>

        </section>

        <section className='pt-[52px] container'>
          <GoodSold />
        </section>


        <section className='pt-[52px] container'>
          <SectionHeader title={contentData?.sh_our_work} />
          <SectionDescription text="Saytda işlərimizi əks etdirən videolarla tanış olun!" />

          <div className='grid grid-cols-2 gap-[32px] mt-[32px] max-[769px]:grid-cols-1'>
            {
              ourWorkData?.slice(-2).map((data, i) => (
                <Card9
                  key={i}
                  title={data?.translate?.title}
                  embed_video={data?.video}
                />
              ))
            }
          </div>

          <div className='flex justify-end mt-[32px] max-[700px]:justify-center'>
            <ViewAllBtn href='/project' />
          </div>
        </section>

        <section className='container pt-[52px]'>
          <SectionHeader title="Haqqımızda" />
          <SectionDescription text="Əlaqə nömrələrimiz və ünvanımız üçün aşağıdakı məlumatlardan yararlana bilərsiniz." />

          <div className='mt-[32px]'>
            <MainAboutUs />
          </div>
        </section>

      </main>
    )
  }
}

const mapStateToProps = (state) => ({
  contentData: state.Data.contentData,
  lang_id: state.Data.lang_id,
  ourWorkData: state.Data.ourWorkData,

})
const mapDispatchToProps = { getourwork }
export default connect(mapStateToProps, mapDispatchToProps)(MainPage)