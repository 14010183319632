import React, { Component } from 'react'
import SectionHeader from './SectionHeader'
import { getMostSaleProducts } from '../actions/MainAction'
import { connect } from 'react-redux'
import SectionDescription from './SectionDescription'
import ViewAllBtn from './ViewAllBtn'
import MostSaleCards from './cards/MostSaleCards'

export class GoodSold extends Component {

  componentDidMount() {
    const { lang_id, getMostSaleProducts, } = this.props;
    getMostSaleProducts(lang_id, 1, "most_sale")
  }

  render() {
    const { mostSaleProductData } = this.props;
    return (
      <section>
        <SectionHeader title='Ən Çox Satılanlar' />
        <SectionDescription text='Ən çox satılan məhsullarımız: keyfiyyət, davamlılıq və müasir dizaynla işinizi mükəmməlləşdirin!' />
        <div className='grid grid-cols-4 gap-[32px] pt-[32px] max-[1080px]:grid-cols-3 max-[650px]:grid-cols-2 max-[425px]:gap-[18px] max-[370px]:grid-cols-1 '>
          {
            mostSaleProductData?.map((data, i) => (
              <MostSaleCards
                key={i}
                image_full_url={data?.images[0]?.image_full_url}
                title={data?.translate?.title}
                price={data?.price}
                id={data?.id}
                bg="bg-[#fff]"
              />
            ))
          }
        </div>

        <div className='flex justify-end mt-[32px]'>
          <ViewAllBtn href="/products" />
        </div>
      </section>
    )
  }
}

const mapStateProps = (state) => ({
  lang_id: state.Data.lang_id,
  mostSaleProductData: state.Data.mostSaleProductData,
})

const mapDispatchToProps = { getMostSaleProducts }
export default connect(mapStateProps, mapDispatchToProps)(GoodSold)